import Vue from 'vue'

export default Vue.component('renderer', {
  functional: true,
  render: function(createElement, context) {
    const {template, ...props} = context.props;
    props.auth = window.localStorage.getItem('auth_token');

    if (!template) return createElement();

    return createElement(context.props.template, {
      attrs: props,
      on: {
        moveIn(payload){
          context.parent.moveIn(payload.detail[0])
        }
      },
      styles:{
        border: "1px solid red"
      }
    })

  }
})
