<template>
  <div class="app-holder">
    <renderer
        @move_in="moveIn"
        :company_id="companyData.gds_owner_id"
        :contact_id="userData.id"
        :template="template"
        v-if="template"
      />
      <div v-else>
        <async-loader></async-loader>
      </div>

  </div>

</template>
<script>
    import AsyncLoader from '../includes/Loading.vue';
    import renderer from "../../Renderer.js"
    import { mapGetters } from 'vuex';
    import { EventBus } from '../../EventBus.js';




    export default {
        data() {
            return {
                template: null,
                loaded: false,
            }
        },
        components: {
            renderer,
            AsyncLoader
        },
        computed: {
            ...mapGetters({
                userData: 'authenticationStore/getUserData',
                companyData: 'authenticationStore/getCompany',
            }),

        },
        props:['gds_id'],
        async created(){
            this.loadComponent();
        },
        methods: {
            async loadComponent(){
                this.template = null;
                let extensions = this.$store.getters['authenticationStore/getExtensionPoints'].settings;

                let ext = extensions.find(e => e.id === this.gds_id );
                // Hack to fill with component name until GDS API returns component names instead of component html tags.
                // Instead of a URL, simply pass in the component you want to render as component and the owner id an any other relevant data will be passed via props.
                // If you still want to return component html tags, use the api call below.
                ext.component = 'custom-bundle-website-theme';


                if(ext.component){
                    this.template = ext.component;
                    this.loaded = true;
                }
 
                // Here we have to string replace owner.id with gds_owner_id.  Doesnt seem like the best way.  We can pass owenr id through pops to the rendering component rather than through the URL, which would maintain proper component architecture.
                // let url = 'https://uat.tenantapi.com/v3/applications/app1782cc28efda463d885fed247e7dc2a9' + ext.url.replace("{{owner.id}}", this.companyData.gds_owner_id);
                //
                // console.log("url", url);
                // let component_response = await this.$http.get(
                //     url,
                //     {
                //         headers: {
                //             'X-storageapi-key': '309365e7685b4b048d79dc7d29bd4f57',
                //             'X-storageapi-date': '1588187243'
                //         }
                //     });
               // this.template = component_response.body;
                //this.template = 'CustomBundleWebsiteTheme';

                this.loaded = true;
            },
            async moveIn(unit){

                await this.$store.dispatch('onBoardingStore/getUnitInfo', {unit_id: unit.id});
                await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.property);
                EventBus.$emit('new_lead');
            }

        },
        watch:{
            id(){
                this.loadComponent();
            },

        }
    }
</script>

<style scoped>

  .app-holder{
    flex: 1
  }

</style>